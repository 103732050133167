import React from "react";
import { Heading, VStack, Button, Flex, FlexProps, Checkbox, Text, HStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

import RegisterStore from "~/stores/RegisterStore";
import { TextInput } from "~/components";
import strings from "~/resources/strings";
import { getDashboardRoute } from "~/resources/routes";

interface IProps extends FlexProps{
	registerStore: RegisterStore;
}

export const FormSection: React.FC<IProps> = observer((props) => {
	const { registerStore, ...rest } = props;
	const componentStrings = strings.components.formSection;

	const goToDashboard = () => {
		const dashUrl = getDashboardRoute();
		if(dashUrl) {
			window.location.href = dashUrl;
		}
	};

	const onSubmit = () => {
		registerStore.createLegalUser(goToDashboard);
	};

	const [documentMaskType, setDocumentMaskType] = React.useState<"cpf" | "cnpj">("cpf");

	return (
		<Flex
			color="primary.500"
			mx="auto"
			justifyContent="center"
			direction="column"
			maxWidth="1280px"
			{...rest}
		>
			<VStack spacing={5} px={5}>
				<Heading
					w={{ base: "100%", md: "70%"}}
				  	textAlign="center"
					mb={5}
				>
					{componentStrings.title}
				</Heading>

				<VStack
					spacing={5}
					maxW="650px"
					w="100%"
				>
					<TextInput
						label={strings.fields.email}
						isDisabled={registerStore.loader.isLoading}
						{...registerStore.formshelf.field("email")}
					/>

					<TextInput
						label={strings.fields.name[documentMaskType]}
						isDisabled={registerStore.loader.isLoading}
						{...registerStore.formshelf.field("fantasyName")}
					/>

					<TextInput
						label={strings.fields[documentMaskType]}
						isDisabled={registerStore.loader.isLoading}
						mask={
							documentMaskType === "cpf"
								? "999.999.999-99"
								: "99.999.999/9999-99"
						}
						{...registerStore.formshelf.field("documentNumber")}
					/>

					<HStack w="100%">
						<Checkbox
							size="md"
							colorScheme="primary"
							isChecked={documentMaskType === "cpf"}
							onChange={() => setDocumentMaskType("cpf")}
						>
							{componentStrings.pf}
						</Checkbox>
						<Checkbox
							size="md"
							colorScheme="primary"
							isChecked={documentMaskType === "cnpj"}
							onChange={() => setDocumentMaskType("cnpj")}
						>
							{componentStrings.pj}
						</Checkbox>
					</HStack>

					<TextInput
						label={strings.fields.password}
						isDisabled={registerStore.loader.isLoading}
						type="password"
						{...registerStore.formshelf.field("password")}
					/>
					<Flex
						w="100%"
						justifyContent="start"
						alignItems="100%"
					 >
						<Checkbox
							size="lg"
							colorScheme="primary"
							isChecked={registerStore.checkTermsOfUse.value}
							onChange={(newValue)=>registerStore.checkTermsOfUse.setValue(newValue.target.checked)}
							 />
						<Text fontSize="md" ml={3} mr={1}>
							{strings.fields.termsAndConditions[0]}
						</Text>
						<a
							href="https://drive.google.com/file/d/1Wh5D-Sig9i0koOrNHqN7hfwRaR6pJv4h/view?usp=sharing"
							download="TermosEcondiçõesCiclik"
						>
							<Text fontSize="md" textDecorationLine="underline">
								{strings.fields.termsAndConditions[1]}
							</Text>
						</a>
						<Text fontSize="md" ml={1}>
							{strings.fields.termsAndConditions[2]}
						</Text>
					</Flex>

				</VStack>

				<Button
					variant="solid"
					colorScheme="primary"
					width="full"
					maxW="655px"
					borderRadius={6}
					borderTopRightRadius={0}
					isLoading={registerStore.loader.isLoading}
					fontSize="xl"
					h="55px"
					onClick={onSubmit}
					disabled={!registerStore.checkTermsOfUse.value}
				>
					{componentStrings.register}
				</Button>
			</VStack>
		</Flex>
	);
});
