import React from "react";
import {
	Grid,
	Image,
	Text,
	GridProps,
	Flex,
	Box,
} from "@chakra-ui/react";

import { Badge, Card } from "~/components";

import strings from "~/resources/strings";

interface IProps extends GridProps { }

export const StepByStepSection: React.FC<IProps> = (props) => {
	const componentStrings = strings.components.stepByStepSection;

	return (
		<Box
			bg="#F1F0F0"
			pb={8}
		>
			<Grid
				maxW="500px"
				mx={{ base: 10, sm: "auto" }}
				templateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
				px={{ sm: "6px", md: 0 }}
				gap={2}
				{...props}
			>
				<Card h="15rem" p={5}>
					<Flex
						flexDirection="row"
						justifyContent="space-between"
					>
						<Text
							fontWeight="bold"
							color="gray.600"
							mb={2}
							w="70%"
							fontSize="md"
						>
							{componentStrings.firstCard.title}
						</Text>
						<Badge
							number={componentStrings.firstCard.number}
							color="white"
							bg="primary.500"
						/>
					</Flex>
					<Text
						color="gray.600"
						fontSize="md"
					>
						{componentStrings.firstCard.text}
					</Text>
				</Card>

				<Card
					h="15rem"
					display="flex"
					justifyContent="center"
				>
					<Image
						w="100%"
						h="100%"
						borderRadius="xl"
						borderTopRightRadius={0}
						src="/group-of-friends.png"
					/>
				</Card>

				<Card h="15rem">
					<Image
						w="100%"
						h="100%"
						borderRadius="xl"
						borderTopRightRadius={0}
						src="/smilingWoman.png"
					/>
				</Card>

				<Card h="15rem" p={5} bg="secondary.500">
					<Flex
						flexDirection="row"
						justifyContent="space-between"
					>
						<Text fontWeight="bold" color="white" mb={2} w="70%" fontSize="md">
							{componentStrings.secondCard.title}
						</Text>
						<Badge
							number={componentStrings.secondCard.number}
							color="gray.600"
							bg="white"
						/>
					</Flex>
					<Text color="white" fontSize="md">
						{componentStrings.secondCard.text}
					</Text>
				</Card>

				<Card h="15rem" p={5}>
					<Flex
						flexDirection="row"
						justifyContent="space-between"
					>
						<Text
							fontWeight="bold"
							color="gray.600"
							mb={2}
							w="50%"
							fontSize="md"
						>
							{componentStrings.thirdCard.title}
						</Text>
						<Badge
							number={componentStrings.thirdCard.number}
							color="white"
							bg="primary.500"
						/>
					</Flex>
					<Text color="gray.600" fontSize="md">
						{componentStrings.thirdCard.text}
					</Text>
				</Card>

				<Card h="15rem">
					<Image
						w="100%"
						h="100%"
						borderRadius="xl"
						borderTopRightRadius={0}
						src="/shakingHands.png"
					/>
				</Card>

			</Grid>
		</Box>
	);
};
