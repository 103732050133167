import React from "react";
import { Box, Flex, Text, Stack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";

import strings from "../../resources/strings";
import { SustainableCycleCard } from "../";

export const SustainableCycleSection: React.FC = () => {
	const componentStrings = strings.components.sustainableCycleSection;

	return (
		<Flex
			direction="column"
			py={10}
			d={{ base: "flex", md: "none", lg: "flex" }}
		>
			<Box>
				<Text
					pr={{ base: 12, md: 20 }}
					color="gray.600"
					fontWeight="100"
					fontSize={{ base: "3xl", lg: "6xl" }}
					textAlign="end"
				>{componentStrings.title[1]}<Text fontWeight="bold" color="gray.600" fontSize={{ base: "3xl", lg: "6xl" }}>{componentStrings.title[2]}</Text>
				</Text>
				<Box w={{ base: "45%", lg: "25%" }} maxWidth="300px" ml="auto" borderWidth={{ base: "4px", lg: "7px"}} borderColor="primary.500" />
			</Box>
			<Stack
				direction={{ base: "column", lg: "row" }}
				wrap="wrap"
				justifyContent={{ base: "center", md: "space-between", xl: "space-around" }}
				alignItems="center"
				mt={10}
			>
				<SustainableCycleCard
					url="/HoldingPhone.png"
					content={componentStrings.card1}
					maxW={275}
					minW={315}
				/>
				<Image
					alt="arrow"
					src="/HorizontalArrow.svg"
					d={{ base: "none", lg: "block" }}
				/>
				<SustainableCycleCard
					url="/location.svg"
					content={componentStrings.card2}
					maxW={220}
				/>
				<Image
					alt="arrow"
					src="/HorizontalArrow.svg"
					d={{ base: "none", lg: "block" }}
				/>
				<SustainableCycleCard
					url="/Store.png"
					content={componentStrings.card3}
					maxW={230}
					mt={10}
				/>
			</Stack>
		</Flex>
	);
};
