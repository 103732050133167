import React from "react";
import {
	Center,
	Image,
	Text,
	VStack,
	TextProps,
	StackProps,
} from "@chakra-ui/react";

interface IProps extends StackProps {
	iconSrc: string;
	description: Array<{
		text: string;
		textProps?: TextProps;
	}>;
}

export const IconText: React.FC<IProps> = (props) => {
	const { iconSrc, description, ...rest } = props;
	return (
		<VStack spacing={4} {...rest}>
			<Center bg="primary.500" rounded="full" w={{base: "40px", md: 50}} h={{base: "40px", md: 50}}>
				<Image
					src={iconSrc}
					w="20px"
					h="20px"
				/>
			</Center>

			<Text>
				{description.map((item, index) => (
					<Text
						color="gray.600"
						key={index}
						textAlign="center"
						fontSize={{ base: "xs", sm: "sm" }}
						{...item.textProps}
					>
						{item.text}
					</Text>
				))}
			</Text>
		</VStack>
	);
};
