import React from "react";
import NextHead from "next/head";

export const SiteHead: React.FC = () => (
	<NextHead>
		<meta
			key="viewport"
			name="viewport"
			content="width=device-width, initial-scale=1, shrink-to-fit=no"
		/>
		<link rel="icon" href="/favicon.ico" />
		<link rel="apple-touch-icon" href="/favicon.ico" />
	</NextHead>
);
