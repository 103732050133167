import React from "react";

import {
	Text,
	Collapse,
	Box,
	Image,
	BoxProps,
} from "@chakra-ui/react";

interface IProps extends BoxProps {
	title: string;
	description: string;
}

export const FaqInfoCard: React.FC<IProps> = (props) => {

	const { title, description, ...rest } = props;
	const [isOpen, setisOpen] = React.useState<boolean>(false);

	return (
		<Box
			onClick={() => setisOpen(!isOpen)}
			boxShadow="xl"
			bg="lightgray"
			borderRadius="7px"
			borderTopRadius={0}
			cursor="pointer"
			p="1.5rem"
			{...rest}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				w="100%"
				alignItems="center"
			>
				<Text
					color="primary.500"
					fontSize={{base: "1rem", lg:"1.2rem"}}
					fontWeight="bold"
					isTruncated
				>
					{title}
				</Text>
				<Image
					w="25px"
					height="25px"
					src={isOpen ? "/minus.svg" : "/plus.svg"}
					alt="arrow"
				/>
			</Box>
			<Collapse
				in={isOpen} animateOpacity
			>
				<Box h="1px" bg="gray.400" mt={5} opacity="0.5" />
				<Text
					mt="1rem"
					textAlign="justify"
					color="gray.600"
					fontSize={{ base: "md", md:"lg" }}
				>
					{description}
				</Text>
			</Collapse>
		</Box>
	);
};
