import React from "react";
import { HStack, Image, Text, Center } from "@chakra-ui/react";

import { GradientDivider } from "~/components";
import strings from "~/resources/strings";

export const IntroSection: React.FC = () => {
	const componentStrings = strings.components.introSection;

	return(
		<Center
			h={{ base: "300px", md: "730px"}}
			w="100%"
			position="relative"
			bg="#00000099"
			flexDirection="column"
		>
			<Image
				src="/medium-shot-man.png"
				zIndex={-1}
				w="100%"
				h="98%"
			/>
			<GradientDivider h="2%" w="100%" />
			<Center
				flexDirection="column"
				position="absolute"
			>
				<HStack spacing={{ base: 2, md: 4 }} border="white 1px solid" borderRadius="full" px={5}>
					<Text color="white" fontWeight="bold" fontSize={{ base: "lg", sm: "xl", md: "4xl" }}>
						{componentStrings.title[0]}

					</Text>
					<Text color="secondary.500" fontWeight="bold" fontSize={{ base: "lg", sm: "xl", md: "4xl" }}>
						{componentStrings.title[1]}
					</Text>
				</HStack>
				<Image
					mt={5}
					src="logoWithoutText.png"
					w={{ base: "10rem", md: "19rem"}}
					h={{ base: "10rem", md: "19rem"}}
				/>
			</Center>
		</Center>
	);
};
