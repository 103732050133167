import React from "react";
import {
	Box,
	BoxProps,
} from "@chakra-ui/react";

interface IProps extends BoxProps { }

export const Card: React.FC<IProps> = (props) => {
	const { children, ...rest } = props;

	return (
		<Box
			bg="white"
			borderRadius="xl"
			borderTopRightRadius={0}
			boxShadow="2xl"
			{...rest}
		>
			{children}
		</Box>
	);
};
