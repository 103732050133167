import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

interface IProps extends HeadingProps { }

export const Title: React.FC<IProps> = (props) => {
	const { children } = props;
	return (
		<Heading
			color="secondary.100"
			textAlign={{base: "center", lg: "start"}}
			{...props}
		>
			{children}
		</Heading>
	);

};

