import strings from "~/resources/strings";
import externalLinks from "~/resources/externalLinks";

export const categorySection = [
	{
		name: strings.footer.category.blog,
		link: externalLinks.blog,
	},
	{
		name: strings.footer.category.allAboutESG,
		link: externalLinks.allAboutESG,
	},
	{
		name: strings.footer.category.solidWastePolicy,
		link: externalLinks.solidWastePolicy,
	},
	{
		name: strings.footer.category.polluterPrinciple,
		link: externalLinks.polluterPrinciple,
	},
];
