// MARK: React
import React from "react";
import { Link, keyframes } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";

// MARK: Next
import Image from "next/image";

// MARK: Implementation
interface IProps { }

const pulse = keyframes`
	0% {
		transform: scale(0.9);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 40px #76ae6a00;
	}

	100% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 #76ae6a00;
	}
`;

export const AbsoluteContactSocialButtons: React.FC<IProps> = () => {
	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};
	return (

		<Box
			position="fixed"
			bottom={18}
			right={8}
			zIndex={999}
			animation={`infinite 2s ease ${pulse}`}
			boxShadow="0 0 0 0 #76ae6a77"
			width={58}
			height={58}
			borderRadius="50%"
		>
			<Link
				cursor="pointer"
				href="https://api.whatsapp.com/send?phone=5571996534134&text=Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20CiClik!"
				onClick={() => onClickGTagEvent("WhatsApp Button Clicked")}
				isExternal
			>
				<Image src="/wpp.svg" alt="Whatsapp" height={58} width={58} />
			</Link>

		</Box>
	);
};
