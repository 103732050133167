import React from "react";
import { Flex, Text } from "@chakra-ui/layout";

interface IProps {
	number: string;
	color: string;
	bg: string;
}

export const Badge: React.FC<IProps> = (props) => {
	const { number, color, bg } = props;
	return (
		<Flex
			bg={bg}
			h={12}
			w={12}
			mt={-2}
			mr={-2}
			alignItems="center"
			justifyContent="center"
			borderBottomRadius={15}
			borderTopLeftRadius={15}
		>
			<Text color={color} fontSize="lg" fontWeight="bold" >
				{number}
			</Text>
		</Flex>
	);
};

