import React from "react";
import { Stack, Image, Text, Flex } from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Title } from "~/components";

export const InfoSection: React.FC = () => (
	<Flex
		bg="primary.500"
		maxH={{ base: "100%", lg: "500px" }}
		p={{ base: 4, lg: 10 }}
		w="100%"
	>
		<Flex
			w="100%"
			mt="12"
			mb="8"
			flexDirection={{ base: "column", lg: "row"}}
			alignItems="center"
			justifyContent="center"
		>
			<Stack maxW="700px" w={{ base: "100%", lg: "50%"}}>
				<Title fontWeight="700" size="2xl" color="white">{strings.components.infoSetcion.title}</Title>
				<Text fontWeight="400" fontSize="xl" textAlign={{base: "justify", lg: "inherit"}} color="white">{strings.components.infoSetcion.description}</Text>
			</Stack>
			<Image
				src="/volunteer-with-recycle.svg"
				width={{ base: "100%", md: "65%", lg: "520px"}}
				height={{ base: "100%", md: "65%", lg: "550px"}}
			/>
		</Flex>
	</Flex>
);
