import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

interface IProps extends TextProps { }

export const Description: React.FC<IProps> = (props) => {
	const { children } = props;
	return (
		<Text
			color="gray.500"
			fontSize="medium"
			fontWeight="light"
			textAlign="justify"
			{...props}
		>
			{children}
		</Text>
	);

};

