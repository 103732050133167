/* eslint-disable @typescript-eslint/no-explicit-any */

import externalLinks from "./externalLinks";

export interface ILink {
	href: string;
	as?: string;
	passHref?: boolean;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

const getRoutes = <T extends IRouteType>(value: T): T => value;

export const getDashboardRoute = () => {
	switch (process.env.NODE_ENV) {
		case "production":
			return "https://dashboard.ciclik.com.br/login";
		default:
			return "https://staging.dashboard.ciclik.com.br/login";
	}
};

const Routes = getRoutes({
	index: (params?: string | null) => ({
		href: "/",
		as: `/${params || ""}`,
	}),
	register: (params?: string | null) => ({
		href: "/register",
		as: `/register/${params || ""}`,
	}),
	faq: (params?: string | null) => ({
		href: "/faq",
		as: `/faq/${params || ""}`,
	}),
	login: () => ({
		href: getDashboardRoute(),
		passHref: true,
	}),
	ciclikSite: () => ({
		href: externalLinks.main,
		passHref: true,
	}),
});

export default Routes;
