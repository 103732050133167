import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";
import { Flex, FlexProps } from "@chakra-ui/layout";
interface IProps extends ImageProps {
	url: string;
	content: string;
	cardProps?: FlexProps;
	containerProps?: FlexProps;
}

export const SustainableCycleCard: React.FC<IProps> = (props) => {
	const { url, containerProps, ...rest } = props;
	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			justifyContent="flex-end"
			{...containerProps}
		>

			<Image
				alt={url}
				src={url}
				{...rest}
			/>
		</Flex>
	);
};
