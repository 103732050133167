import React from "react";
import { Box } from "@chakra-ui/react";
import { AbsoluteContactSocialButtons, GradientDivider } from "../../components";
import { SiteHead } from "../../layout/SiteHead";
import { Navbar } from "../../layout/Navbar";
import { Footer } from "../../layout/Footer";

import OgTags from "../../resources/OgTags";
import strings from "../../resources/strings";

interface IProps {
	disableNavbarGradient?: boolean;
}

export const MainLayout: React.FC<IProps> = (props) => {
	const { disableNavbarGradient, children } = props;

	return (
		<Box minH="100vh">
			<OgTags
				{...strings.ogTags}
				url={process.env.NEXT_PUBLIC_PAGE_URL ? `https://${process.env.NEXT_PUBLIC_PAGE_URL}` : null}
			/>
			<Navbar />
			{!disableNavbarGradient && (
				<GradientDivider />
			)}
			<SiteHead />
			{children}
			<Footer />
			<AbsoluteContactSocialButtons />
		</Box>
	);
};
