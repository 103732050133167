import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import { SignCard } from "~/components";
import strings from "../../resources/strings";

export const AboutUsSection: React.FC = () => {
	const componentStrings = strings.components.aboutUsSection;
	return (
		<>
			<Flex
				bgImage={"/FourUpHands.png"}
				bgRepeat="no-repeat"
				bgPosition="center"
			>
				<Flex
					direction={{ base: "column-reverse", md: "row" }}
					alignItems="center"
					justifyContent="center"
					width="100%"
					bg="#09BE54C7"
					mx="auto"
					py={8}
				>
					<Image
						alt="phones"
						src="/TwoPhones.png"
						alignSelf="center"
						width="40%"
						minW={280}
						maxW={450}
					/>
					<Text
						textAlign={{ base: "center", md: "end" }}
						fontSize={{ base: "xl", md: "3xl" }}
						width={{ base: "80%", md: "25%" }}
						color="white"
						lineHeight="36px"
					>
						{componentStrings.content[1]}<Text color="white" fontSize={{ base: "xl", md: "3xl" }} as="span" fontWeight="bold">{componentStrings.content[2]}</Text>
						{componentStrings.content[3]}<Text color="white" fontSize={{ base: "xl", md: "3xl" }} as="span" fontWeight="bold">{componentStrings.content[4]}</Text>
						{componentStrings.content[5]}<Text fontSize={{ base: "xl", md: "3xl" }} as="span" color="secondary.500">{componentStrings.content[6]}</Text>
					</Text>
				</Flex>
			</Flex>
			<Flex
				mx="auto"
				maxH={{ lg: "580px" }}
				maxW={{ lg: "1280px" }}
				flexDirection={{ base: "column-reverse", lg: "row" }}
			>
				<SignCard
					borderBottomRightRadius={{ base: 15, lg: 0 }}
					maxW={{ base: "100%" }}
					minW={{ base: "30%" }}
					mx={0}
				/>
				<Box>
					<Image
						display={{ base: "none", md: "inherit"}}
						h="100%"
						w="100%"
						src="ciclik-green-seal.png"
					/>
				</Box>
			</Flex>
		</>

	);
};
