import React from "react";
import {
	Flex,
	Grid,
	Image,
	Box,
	Center,
	TextProps,
} from "@chakra-ui/react";

import { IconText } from "./IconText";
import strings from "~/resources/strings";

export const ImperativeSection: React.FC = () => {
	const componentStrings = strings.components.imperativeSection;

	return (
		<Center bg="white" h={500} my={16}>
			<Flex
				p={{ base: 1, lg: 5}}
				w="100%"
				maxW={800}
				mx="auto"
				justifyContent="space-between"
				h={400}
				position="relative"
			>
				<Image
					position="absolute"
					src="computer.svg"
					w="700px"
					top={-120}
					right={250}
					h="580px"
					zIndex={100}
					display={{ base: "none", lg: "initial" }}
				/>
				<Box
					h="100%"
					w="20%"
					display={{ base: "none", lg: "initial" }}
				/>
				<Flex
					h="100%"
					w={{ lg: "80%" }}
					justifyContent={{ base: "center", lg: "flex-end" }}
					p={{ base: 6, sm: 10}}
					shadow="2xl"
					mx="auto"
				>
					<Grid
						templateColumns="1fr 1fr"
						maxW={350}
						flexWrap="wrap"
						flexDirection="column"
					>
						{componentStrings.grid.map((item, index) => {
							const resProps = (): TextProps => {
								switch (index) {
									case 0:
									default:
										return {
											p: 3,
											borderRight: "#09BE54 1px solid",
											borderBottom: "#09BE54 1px solid",
										};
									case 1:
										return {
											p: 3,
											borderBottom: "#09BE54 1px solid",
										};
									case 2:
										return {
											p: 3,
											borderRight: "#09BE54 1px solid",
										};
									case 3:
										return {
											p: 3,
										};
								}
							};
							return (
								<IconText
									key={index}
									iconSrc={item.icon}
									description={[
										{
											text: item.text[0],
											textProps: { fontWeight: "bold"},
										},
										{
											text: item.text[1],
										},
									]}
									{...resProps()}
								/>
							);
						})}
					</Grid>
				</Flex>
			</Flex>
		</Center>
	);
};
