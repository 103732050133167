import Routes, { ILink } from "./routes";
import strings from "./strings";

interface INavigationLinks {
	name: string;
	link: ILink;
	passHref?: boolean;
}

const mainRoutesStrings = strings.routeNames;

const navigationLinks: INavigationLinks[] = [
	{
		name: mainRoutesStrings.home,
		link: Routes.ciclikSite(),
		passHref: true,
	},
	{
		name: mainRoutesStrings.login,
		link: Routes.login(),
		passHref: true,
	},
];

export default navigationLinks;
