import React from "react";
import { VStack, Heading, Image, Button, Text, Box } from "@chakra-ui/react";
import Router from "next/router";
import strings from "~/resources/strings";

export const KnowMoreSection: React.FC = () => {
	const componentStrings = strings.components.knowMoreSection;
	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	const onClickKnowMore = (eventName: string) => {
		onClickGTagEvent(eventName);
		Router.push("/register");
	};
	return (
		<Box
			bg="#F1F0F0"
		>
			<VStack
				maxW={800}
				mx="auto"
				py={20}
				spacing={10}
				px={5}
			>
				<Heading
					fontSize={{ base: "3xl", md: "5xl"}}
					textAlign="center"
				>
					{componentStrings.title}
				</Heading>
				<Image
					src="CiclikSpinner.svg"
				/>

				<Text
					textAlign={{ base: "justify", md: "center" }}
					fontSize="xl"
					color="gray.600"
					fontWeight="bold"
				>

					{componentStrings.description[0]}
					<Text
						color="gray.600"
						textAlign={{ base: "center" }}
						fontSize="xl"
					>
						{componentStrings.description[1]}
					</Text>
				</Text>
				<Button
					variant="solid"
					colorScheme="primary"
					width="full"
					borderRadius={6}
					borderTopRightRadius={0}
					fontSize="xl"
					w="200px"
					h="55px"
					onClick={()=>onClickKnowMore("Know More Clicked")}
				>
					{componentStrings.knowMore}
				</Button>
			</VStack>
		</Box>
	);
};
