import React from "react";
import {
	VStack,
	Heading,
	Flex,
	Image,
	Button,
	StackProps,
} from "@chakra-ui/react";
import {} from "~/components";
import Router from "next/router";
import strings from "~/resources/strings";

interface IProps extends StackProps{ }

export const SignCard: React.FC<IProps> = (props) => {
	const componentStrings = strings.components.signCard;
	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	const onClickSignNow = (eventName: string) => {
		onClickGTagEvent(eventName);
		Router.push("/register");
	};

	return(
		<VStack
			bg="blackAlpha.700"
			maxW="340px"
			p={8}
			spacing={5}
			borderBottomRadius={15}
			{...props}
		>
			<Heading
				color="secondary.500"
				fontSize="4xl"
				textAlign="center"
			>
				{componentStrings.title}
			</Heading>

			<Flex flexDirection="column">
				{componentStrings.description.map((item, index) => (
					<Flex
						key={index}
						alignItems="center"
						mb={5}
					>
						<Image
							src="CiclikSpinner.svg"
							w={50}
						/>
						<Heading ml={6} color="white" fontSize="2xl">
							{item}
						</Heading>
					</Flex>
				))}
			</Flex>

			<Button
				variant="solid"
				colorScheme="primary"
				width="full"
				borderRadius={6}
				borderTopRightRadius={0}
				fontSize="xl"
				w="200px"
				h="55px"
				onClick={()=>onClickSignNow("Sign Now Clicked")}

			>
				{componentStrings.signNow}
			</Button>
		</VStack>
	);
};
