import React from "react";
import { Text, Input, InputProps, FormLabel, FormControl } from "@chakra-ui/react";
import InputMask from "react-input-mask";

interface IProps extends InputProps {
	mask?: string;
	label: string;
	name?: string;
	error?: string | null;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = (props: IProps) =>{
	const {error, label, mask, onChange, ...rest} = props;
	return (
		<FormControl>
			<FormLabel color="gray.500">{label}</FormLabel>
			<Input
				borderRadius="lg"
				bg="lightgray"
				color="gray.700"
				borderColor={error ? "red.500" : undefined}
				as={InputMask}
				mask={mask || []}
				onChange={onChange}
				{...rest}
			/>
			{error && (
				<Text color="red.500" size="small">{error}</Text>
			)}
		</FormControl>
	);
};
